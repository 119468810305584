import React from 'react';
import { Coin } from './Coin'

function App() {
  return (
    <Coin />
  );
}

export default App;
